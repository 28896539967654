import Application from 'components/comachicart/Application';
import ComachiFeature from 'components/comachicart/ComachiFeature';
import CustomersVoice from 'components/comachicart/CustomersVoice';
import {TopFAQ} from 'components/comachicart/FAQ';
import Layout from 'components/comachicart/Layout';
import Price from 'components/comachicart/Price';
import Thumnail from 'components/comachicart/Thumbnail';
import TopNews from 'components/comachicart/TopNews';
import SEO from 'components/seo';
import React from 'react';

const ComachiPage = () => {
  const thumnailUrl = 'comachi_lp/top/thumnail.png';

  return (
    <Layout>
      <SEO title='comachicart' path='comachicart' />
      <TopNews />
      <Thumnail filename={thumnailUrl} />
      <ComachiFeature />
      <CustomersVoice />
      <Price visibleVoice={false} />
      <Application />
      <TopFAQ />
    </Layout>
  );
};

export default ComachiPage;
